@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background: #F0F0F1;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-tabs__tab-list {
  display: flex;
  border-bottom: 2px solid #BECAE6;
}

.react-tabs__tab {
  width: 50%;
  border: none;
  text-align: center;
  padding: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(54, 57, 61, .4);
}

.react-tabs__tab--selected {
  color: #284FAE;
  border-bottom: 2px solid #284FAE;
}

.Collapsible__trigger {
  display: block;
  position: relative;
}

.Collapsible__trigger::after {
  content: '';
  background: url('./assets/images/svg/ic-arrow-down-blue.svg');
  width: 16px;
  height: 10px;
  position: absolute;
  top: 8px;
  right: 0px;
  transition: transform .2s ease-in;
  transform: rotate(180deg);
}

.Collapsible__trigger.is-open::after {
  transform: rotate(0deg);
}

.Collapsible__contentInner {
  margin-top: 11px;
  padding-top: 16px;
  border-top: 1px solid #82949E;
}

.underline {
  text-decoration-line: underline;
}
.ml-4 {
  margin-left: 1rem;
}
.font-black {
  font-weight: 900;
}

.text-tory-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(40 79 171/var(--tw-text-opacity));
  cursor: pointer;
}

.w-full {
  width: 100%;
}


@media (min-width: 768px) {
  .react-tabs__tab {
    width: inherit;
    padding: 8px 24px;
  }
  .Collapsible__contentInner {
    border-top: none;
  }
}